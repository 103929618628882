<template>
  <widget>
    <div class="d-flex justify-content-between mb-3">
      <b-card-title>
        <span>{{ $t("events.title") }}</span>
        <span class="dot"> • </span>
        <!--      <span v-if="!agenda" class="gray">{{
          $t(`events.list.filter.${selectedFilter}`)
        }}</span> -->
        <span class="gray">Agenda</span>
      </b-card-title>
      <div>
        <b-button
          v-if="canCreate"
          v-b-modal.modal-create-event
          variant="primary"
          class="action d-inline-block"
          block
          @click="handleCalendarMeetingOpen(isStaff)"
        >
          <feather-icon icon="PlusCircleIcon" size="17" />
          <span>
            {{ $t("calendar.action.create") }}
          </span>
        </b-button>
        <!--    <v-select
          v-if="!agenda"
          v-model="selectedFilter"
          class="mt-1 pl-1 filter-select d-inline-block"
          :clearable="false"
          :searchable="false"
          :options="filterOptions"
          :get-option-label="(option) => $t(`events.list.filter.${option}`)"
          @option:selected="handleFilterSelected"
        />
        <feather-icon
          v-if="!agenda"
          icon="CalendarIcon"
          size="20"
          class="text-primary edit-icon ml-1"
          role="button"
          @click="agenda = !agenda"
        />
        <feather-icon
          v-if="agenda"
          icon="GridIcon"
          size="20"
          class="text-primary edit-icon ml-1"
          role="button"
          @click="agenda = !agenda"
        /> -->
      </div>
    </div>
    <!--  <base-grid-container
      v-if="!agenda"
      v-slot="{ item }"
      :items="events"
      :loading="isLoading"
      :loading-next="isLoadingNextPage"
      :placeholder="placeholder"
      :placeholder-message="$t('events.list.empty')"
      :has-more="hasMore"
      :agendaItem="true"
      :typeItem="false"
      @load-next="handleLoadNextPage"
    >
      <event-card :event="item" :can-view="canView" />
    </base-grid-container> -->
    <events-agenda />

    <!--  <b-button
      v-if="events.length < 1 && !agenda"
      variant="outline-primary"
      class="d-block mx-auto font-weight-bold past-events-button"
      @click="handlePastEvents"
    >
      {{ $t("events.list.actions.past-events") }}
    </b-button> -->
    <!-- Create event -->
    <b-modal
      id="modal-create-event"
      :title="$t('events.actions.create-event')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :ok-disabled="!isNotDisabled"
      @ok="addEvent"
    >
      <b-form>
        <b-form-group>
          <label for="name">{{ $t("form-create-item.title") }}:</label>
          <b-form-input id="name" v-model="eventInput.name" type="text" />
        </b-form-group>
        <b-form-group>
          <label for="type">{{ $t("events.actions.type-options") }}:</label>
          <v-select
            id="type"
            v-model="selectedOption"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            @option:selected="handleFilterSelected"
            @input="modality"
          />
        </b-form-group>
        <!-- Accessibility -->
        <!--         <b-form-group>
          <label
            for="type"
          >{{ $t("events.actions.accessibility.title") }}:</label>
          <v-select
            id="type"
            v-model="accessibilityOption"
            :clearable="false"
            :searchable="false"
            :options="accessibilityOptions"
            @input="accessibility"
          />
        </b-form-group> -->
        <!-- Organizer -->
        <b-form-group>
          <label for="type">{{ $t("events.actions.organizer.title") }}:</label>
          <b-form-checkbox v-model="showCreated" switch />
        </b-form-group>
        <b-form-group>
          <label
            class="mt-2"
            for="description"
          >{{ $t("form-create-item.description") }}:</label>
          <quill-editor v-model="eventInput.description" />
        </b-form-group>
        <b-form-group
          :label="`${$t('calendar.meeting-form.start-date.label')}*  (${$t(
            'events.event-details.local-time'
          )})`"
        >
          <VueCtkDateTimePicker
            v-model="eventInput.startDate"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
          />
        </b-form-group>
        <b-form-group
          :label="`${$t('calendar.meeting-form.end-date.label')}*  (${$t(
            'events.event-details.local-time'
          )})`"
        >
          <VueCtkDateTimePicker
            v-model="eventInput.endDate"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
            :min-date="eventInput.startDate"
          />
        </b-form-group>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t("form-create-item.image-select") }}
          </p>
          <b-media
            no-body
            vertical-align="center"
            class="d-flex align-items-center flex-column flex-md-row"
          >
            <b-media-aside>
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                style="background: #c0c0c0"
              />
            </b-media-aside>
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  v-model="eventInput.thumbnailURL"
                  class="pointer"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </b-modal>
  </widget>
</template>

<script>
import vSelect from 'vue-select';
import { EventType } from '@copernicsw/community-common';

import {
  EVENTS_STORE_MODULE_NAME,
  EVENTS_ACTIONS,
} from '@/views/apps/events/constants/events-store-constants';
import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';
import OrderBy from '@/@core/constants/OrderBy';
import { quillEditor } from 'vue-quill-editor';

import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import Widget from '@core/widgets/Widget.vue';
import BaseGridContainer from '@core/components/containers/base/BaseGridContainer.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { checkPermissions } from '@/@core/utils/roles-utils';
import FileTypes from '@/@core/constants/FileTypes';
import EventsAgenda from '@/views/apps/events/components/EventsAgenda';
import EventCard from './EventCard.vue';

const EventsFilters = Object.freeze({
  all: () => ({
    orderByDate: OrderBy.ASC,
  }),
  upcoming: () => ({
    orderByDate: OrderBy.ASC,
    sinceStartDate: Date.now(),
  }),
  past: () => ({
    orderByDate: OrderBy.DESC,
    untilEndDate: Date.now() - 1,
  }),
});

export default {
  name: 'EventsList',
  components: {
    vSelect,
    Widget,
    BaseGridContainer,
    EventCard,
    EventsAgenda,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      events: [],
      isLoading: true,
      isLoadingNextPage: false,
      hasMore: false,
      isModalVisible: false,
      currentPage: 0,
      selectedFilter: 'upcoming',
      eventInput: {},
      imageSrc: null,
      selectedOption: '',
      accessibilityOption: '',
      showCreated: false,
      agenda: false,
    };
  },
  computed: {
    isNotDisabled() {
      return (
        this.eventInput.endDate && this.eventInput.startDate && this.eventInput.name
      );
    },
    FileTypes() {
      return FileTypes;
    },
    placeholder() {
      return EventsPlaceholder;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    filterOptions() {
      return Object.keys(EventsFilters);
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    typeOptions() {
      return [
        this.$t('events.event-details.online-event'),
        this.$t('events.event-details.on-site-event'),
        this.$t('events.event-details.hybrid'),
      ];
    },
    /**
     * @returns a function that creates the filter configuration object
     */
    filterConfigurationFactory() {
      return EventsFilters[this.selectedFilter];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    config() {
      return {
        wrap: true,
        enableTime: true,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'Y-m-d h:i K',
        minuteIncrement: 1,
        disableMobile: 'true',
      };
    },
    accessibilityOptions() {
      return [
        this.$t('events.actions.accessibility.invitation'),
        this.$t('events.actions.accessibility.with-request'),
        this.$t('events.actions.accessibility.without-request'),
      ];
    },

    canCreate() {
      return checkPermissions(
        'create',
        'event',
        this.loggedMemberRoles,
        this.currentCollective,
      );
    },
    canView() {
      return checkPermissions(
        'view',
        'event',
        this.loggedMemberRoles,
        this.currentCollective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
  },
  async created() {
    this.isLoading = true;
    this.updateBreadcrumbs();
    // await this.fetchEvent();
    // await this.fetchClassifiers();
    this.selectedOption = this.typeOptions[0];

    await this.fetchApps();
    this.isLoading = false;
  /*   if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
    await this.agendaDisplay();
    await this.handleLoadNextPage();
    this.selectedOption = this.typeOptions[0];
    this.accessibilityOption = this.accessibilityOptions[0]; */
  },
  methods: {
    updateBreadcrumbs() {
      this.$store.commit("app/SET_BREADCRUMBS", null);
    },
    async fetchApps() {
      this.agenda = this.$store.getters.apps.apps[53]?.customization?.agendaDisplayOptions;
    },
    async agendaDisplay() {
      const res = this.$store.getters.apps;
      if (res.apps[53]?.customization !== null) {
        this.agenda = res.apps[53]?.customization?.agendaDisplayOptions;
      }
    },
    async handleLoadNextPage() {
      if (!this.isLoading && this.events && this.events.length < this.total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchEvent();
        this.agendaKey += 1;
        this.isLoadingNextPage = false;
      }
      /*       this.isLoading = true;
      this.currentPage += 1;

      if (this.currentPage > 1) {
        this.isLoadingNextPage = true;
      }
      const response = await this.$store.dispatch(
        EVENTS_ACTIONS.fetchEventList,
        {
          visibleOncommunityKey: this.currentCollective.key,
          excludeTypes: [EventType.Meeting],
          count: 16,
          page: this.currentPage,
          ...this.filterConfigurationFactory(),
        },
      );

      this.currentPage === 1
        ? (this.events = response.data.data)
        : this.events.push(...response.data.data);
      this.hasMore = this.events.length < response.data.meta.total;
      this.isLoadingNextPage = false;
      this.isLoading = false; */
    },
    async handleFilterSelected() {
      this.isLoading = true;
      this.currentPage = 0;
      this.events = [];
      await this.handleLoadNextPage();
      this.isLoading = false;
    },
    handlePastEvents() {
      this.selectedFilter = 'past';
      //     this.handleFilterSelected();
    },
    handleCalendarMeetingOpen(isStaff) {
      if (isStaff) this.isModalVisible = true;
    },
    async fetchEvent() {
      await this.$store.dispatch('getItems', {
        itemType: 'events',
        page: 1,
        forceAPICall: true,
        perPage: 100,
        customName: 'agenda',
        requestConfig: {
          visibleOncommunityKey: this.currentCollective.key,
          isForAgenda: true,
        },
      });

      const response = this.$store.getters.agenda;
      this.total = response.meta ? response.meta.total : 0;
      this.events = response.data;
      return response?.data;
    },
    unixTimestamp(date) {
      const newDate = new Date(date);
      return Math.floor(newDate.getTime() / 1000);
    },
    async addEvent() {
      try {
        const startDate = this.eventInput.startDate ? new Date(this.eventInput.startDate) : null;
        const endDate = this.eventInput.endDate ? new Date(this.eventInput.endDate) : null;
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'events',
            page: this.lastLoadedPage,
            perPage: 100,
            communitySlug: this.currentCollective?.slug,
            customName: 'agenda',
            requestConfig: {
              name: { en: this.eventInput.name },
              description: { en: this.eventInput.description },
              startDate,
              endDate,
              withShared: true,
              modality: this.eventInput.modality,
              showCreated: this.showCreated,
              isMeeting: 0,
              type: 27452,
              thumbnailURL: this.eventInput.thumbnailURL,
              // orderByDate: -1,
            },
          },
        });
        /*         await this.$store.dispatch(EVENTS_ACTIONS.createEvent, {
          locale: this.$store.state.locale.currentLocale,
          collectiveKey: this.$store.getters.currentCollective.key,
          event: this.eventInput,
          withShared: true,
          modality: this.eventInput.modality ?? 1,
          accessibility: this.eventInput.accessibility,
          showCreated: this.showCreated,
          eventType: 27452,
          thumbnailURL: this.eventInput.thumbnailURL,
        }); */
        this.eventInput = {};
        this.notifySuccess(this.$t('events.actions.success-creation'));
        this.currentPage = 0;
        this.handleLoadNextPage();
      } catch (error) {
        this.notifyError(this.$t('events.actions.error-creation'));
        this.eventInput = {};
      }
    },
    modality() {
      this.$emit('update-event', this.eventInput);
      switch (this.selectedOption) {
        case this.typeOptions[1]:
          this.eventInput.modality = 0;
          return;
        case this.typeOptions[2]:
          this.eventInput.modality = 2;
          return;
        case this.typeOptions[0]:
          this.eventInput.modality = 1;
      }
    },
    accessibility() {
      switch (this.accessibilityOption) {
        case this.accessibilityOptions[1]:
          this.eventInput.accessibility = 1;
          return;
        case this.accessibilityOptions[2]:
          this.eventInput.accessibility = 0;
          return;
        case this.accessibilityOptions[0]:
          this.eventInput.accessibility = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
@import "~@core/scss/vue//bootstrap-vue/_date-picker.scss";

.dot {
  color: #48a2a9;
  margin: 0 5px;
}
.gray {
  color: #82818d;
  font-size: 16px;
}
.filter-select {
  min-width: 150px;
  @include media-breakpoint-up(md) {
    min-width: 250px;
  }
}
.past-events-button {
  margin-top: -20px;
}
.reference-icon {
  border-radius: 2px;
  height: 10px;
  width: 10px;
}
.action {
  line-height: 20px;
  width: 120px;
  padding: 0.4rem;
}
</style>
