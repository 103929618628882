<template>
  <section>
    <events-grid />
  </section>
</template>

<script>
import EventsGrid from '@/views/apps/events/components/EventsGrid.vue';

export default {
  name: 'EventsList',
  components: { EventsGrid },
};
</script>
